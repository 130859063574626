























































import { WithdrawRequestStatusesArray } from '@/helpers/withdrawRequestHelpers';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import * as vr from '@/helpers/validation';
import { PropType } from 'vue';
import { mapState } from 'vuex';

export default {
  name: 'WithdrawRequestsFilterPanel',
  components: {
    VExtendedDataPicker
  },
  props: {
    noState: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    }
  },
  data(): any {
    return {
      dateMenu: false,
      statuses: WithdrawRequestStatusesArray,
      tempDate: [],
      formIsValid: false,
      dates: {
        from: null,
        to: null
      },
      filter: {
        date: [],
        id: null,
        operatorUserId: null,
        status: null
      }
    };
  },
  computed: {
    ...mapState('WithdrawRequests', ['loading']),
    dateIsOk(): boolean {
      const length = this.tempDate.length;
      return length === 2 || length === 0;
    },
    rules(): any {
      return {
        id: [
          (v) => {
            if (!v) {
              return true;
            }

            let result = vr.integer(v);

            if (result !== true) {
              return result;
            }

            return vr.positiveNumber(v);
          }
        ]
      };
    }
  },
  methods: {
    submit(): void {
      const form = this.$refs.form;
      form.validate();
      this.$nextTick((): void => {
        if (this.formIsValid) {
          this.$emit('submit', { ...this.filter, ...this.dates });
        }
      });
    }
  }
};
