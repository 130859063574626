

































import { mapActions, mapState, mapGetters } from 'vuex';
import { roundNumber } from '@/helpers/roundNumber';
import { DateTime } from 'luxon';

import WithdrawRequestsFilterPanel from '@/views/WithdrawRequestsView/WithdrawRequestsFilterPanel.vue';
import WithdrawRequestsTable from '@/views/WithdrawRequestsView/WithdrawRequestsTable.vue';
import BaseLoader from '@/components/core/BaseLoader.vue';
import VTabsHash from '@/components/VTabsHash.vue';

import { VuetifyTableOptions, WithdrawRequestItem } from '@/api/schema';
import {
  WITHDRAW_REQUEST_STATUSES,
  WITHDRAW_REQUEST_TABS,
  WithdrawRequestTabsWithLabel
} from '@/helpers/withdrawRequestHelpers';

const defaultPageData = {
  page: 1,
  itemsPerPage: 10
};

const defaultFilter = {
  state: null,
  operatorUserId: null,
  id: null,
  from: null,
  to: null
};

export default {
  name: 'WithdrawRequestsView',
  components: {
    BaseLoader,
    VTabsHash,
    WithdrawRequestsTable,
    WithdrawRequestsFilterPanel
  },
  data(): any {
    return {
      tabs: WithdrawRequestTabsWithLabel,
      tab: 0,
      filter: {
        ...defaultFilter
      },
      pageData: { ...defaultPageData }
    };
  },
  computed: {
    ...mapState('WithdrawRequests', [
      'list',
      'loading',
      'total',
      'pendingTotal'
    ]),
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('app', ['isSuperAdmin']),
    pendingCount(): number {
      return this.pendingTotal;
    },

    currentTabId(): string {
      return this.tabs[this.tab].id;
    },

    isPending(): boolean {
      return this.currentTabId === WITHDRAW_REQUEST_TABS.PENDING;
    },

    listWithdrawRequests(): WithdrawRequestItem[] {
      if (this.loading.list) return [];

      return this.list;
    }
  },
  watch: {
    operatorId: {
      handler(newVal: number, oldVal: number): void {
        if (newVal && newVal !== oldVal) {
          this.pageData = { ...defaultPageData };
          this.filter = { ...defaultFilter };
          this.load();
        }
      },
      immediate: true
    },
    currentTabId: {
      handler(newVal: string, oldVal: string): void {
        if (newVal && newVal !== oldVal) {
          this.pageData = { ...defaultPageData };
          this.filter = { ...defaultFilter };
          this.load();
        }
      },
      immediate: true
    },
    pageData: {
      handler(
        newVal: VuetifyTableOptions,
        oldVal: VuetifyTableOptions
      ): void {
        if (
          newVal &&
          (newVal?.page !== oldVal?.page ||
            newVal?.itemsPerPage !== oldVal?.itemsPerPage)
        ) {
          this.load();
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('WithdrawRequests', ['getList']),
    rowClickHandler(item: WithdrawRequestItem): void {
      this.$router.push({
        name: this.isSuperAdmin
          ? 'account-operator-token-withdraw-request-item'
          : 'withdraw-request-item',
        params: {
          operationId: item.id.toString()
        }
      });
    },
    formatDate(date: string, start: boolean = false): string {
      if (!date) return;

      const format = 'yyyy-LL-dd HH:mm:ss';

      if (start) {
        return DateTime.fromISO(date).startOf('day').toFormat(format);
      }

      return DateTime.fromISO(date).endOf('day').toFormat(format);
    },
    onFilterChanged(data: {
      from: string;
      to: string;
      id: number;
      operatorUserId: string;
      state: string;
    }): void {
      this.filter = {
        ...this.filter,
        ...data,
        from: this.formatDate(data.from, true),
        to: this.formatDate(data.to)
      };
      this.pageData = { ...this.pageData, page: 1 };
      this.load();
    },
    roundNumber,
    updateOptionsHandler(opts: VuetifyTableOptions): void {
      this.pageData = { ...opts };
    },
    load(): void {
      if (!this.operatorId) {
        return;
      }

      if (this.loading.list) {
        return;
      }

      const payload = {
        ...this.filter,
        page: this.pageData.page - 1,
        size: this.pageData.itemsPerPage
      };

      if (this.isPending) {
        payload.state = WITHDRAW_REQUEST_STATUSES.CREATED;
      }

      this.getList(payload);
    }
  }
};
